import { Fragment, lazy } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Transitions } from './Transitions';
import { RequireRole } from './components/shared/Auth/RequireRole';

const DashboardPage = lazy(() => import('./components/pages/Dashboard/DashboardPage'));

const MainStopPage = lazy(() => import('./components/pages/Navigation/MainStopPage'));

const MainNetworksPage = lazy(() => import('./components/pages/Navigation/MainNetworkPage'));

const MainZonePage = lazy(() => import('./components/pages/Navigation/MainZonePage'));

const MainActiveNetworkPage = lazy(() => import('./components/pages/Navigation/MainActiveNetworkPage'));

const MainTravellerProfilePage = lazy(() => import('./components/pages/Navigation/MainTravellerProfilePage'));

const MainProductsPricingPage = lazy(() => import('./components/pages/Navigation/MainProductsPricingPage'));

const MainSalesChannelsPage = lazy(() => import('./components/pages/Navigation/MainSalesChannelsPage'));

const MainSalesRulesPage = lazy(() => import('./components/pages/Navigation/MainSalesRulesPage'));

const MainShiftPage = lazy(() => import('./components/pages/Navigation/MainShiftPage'));

const MainSalesEquipmentPage = lazy(() => import('./components/pages/Navigation/MainSalesEquipmentPage'));

const MainCustomerPage = lazy(() => import('./components/pages/Navigation/MainCustomerPage'));

const MainVehiclePlanningPage = lazy(() => import('./components/pages/Navigation/MainVehiclePlanningPage'));
const VehicleTypePlanEntriesPage = lazy(() => import('./components/pages/Vehicles/VehiclePlanning/VehicleTypePlanEntries/VehicleTypePlanEntriesPage'));

const MainUserAccessPage = lazy(() => import('./components/pages/Navigation/MainUserAccessPage'));

const MainReportFinancialPage = lazy(() => import('./components/pages/Navigation/MainReportFinancialPage'));
const DepositReportPage = lazy(() => import('./components/pages/Reports/Financial/DepositReport/DepositReportPage'));
const PaymentReportPage = lazy(() => import('./components/pages/Reports/Financial/PaymentReport/PaymentReportPage'));
const RevenueRecognitionReportPage = lazy(() => import('./components/pages/Reports/Financial/RevenueRecognitionReport/​RevenueRecognitionReportPage'));
const SalesReportPage = lazy(() => import('./components/pages/Reports/Financial/SalesReport/SalesReportPage'));
const ServicesReportPage = lazy(() => import('./components/pages/Reports/Financial/ServicesReport/ServicesReportPage'));
const ShiftReportPage = lazy(() => import('./components/pages/Reports/Financial/ShiftReport/ShiftReportPage'));
const TicketsReportPage = lazy(() => import('./components/pages/Reports/Financial/TicketsReport/TicketsReportPage'));
const TicketPaxReportPage = lazy(() => import('./components/pages/Reports/Financial/TicketPaxReport/TicketPaxReportPage'));

const MainReportOperationsPage = lazy(() => import('./components/pages/Navigation/MainReportOperationsPage'));
const DeparturesReportPage = lazy(() => import('./components/pages/Reports/Operations/DeparturesReport/DeparturesReportPage'));
const LoadReportPage = lazy(() => import('./components/pages/Reports/Operations/LoadReport/LoadReportPage'));
const StopUsageReportPage = lazy(() => import('./components/pages/Reports/Operations/StopUsageReport/StopUsageReportPage'));
const TripsReportPage = lazy(() => import('./components/pages/Reports/Operations/TripsReport/TripsReportPage'));

const TicketsPage = lazy(() => import('./components/pages/Tickets/Ticket/Edit/TicketsPage'));
const TicketEditPage = lazy(() => import('./components/pages/Tickets/Ticket/Edit/TicketEditPage'));
const TicketSellPage = lazy(() => import('./components/pages/Tickets/Ticket/Sell/TicketSellPage'));
const TicketPaymentCallbackPage = lazy(() => import('./components/pages/Tickets/Ticket/Sell/TicketPaymentCallbackPage'));

const DeparturesPage = lazy(() => import('./components/pages/Tickets/Departures/DeparturesPage'));
const DepartureDetailsPage = lazy(() => import('./components/pages/Tickets/Departures/DepartureDetailsPage'));

const VehiclesPage = lazy(() => import('./components/pages/Vehicles/Vehicles/VehiclesPage'));
const VehicleTypesPage = lazy(() => import('./components/pages/Vehicles/VehicleTypes/VehicleTypesPage'));

const UsersPage = lazy(() => import('./components/pages/Users/Manage/UsersPage'));
const UserDetailsPage = lazy(() => import('./components/pages/Users/Manage/Details/UserDetailsPage'));
const UserDetailsSection = lazy(() => import('./components/pages/Users/Manage/Details/Edit/UserDetailsSection'));
const UserMessagesSection = lazy(() => import('./components/pages/Users/Manage/Details/Messages/UserMessagesSection'));

const NotFound = lazy(() => import('src/components/pages/Error/NotFound'));
const Unauthorized = lazy(() => import('src/components/pages/Error/Unauthorized'));

const CustomersPage = lazy(() => import('./components/pages/Customers/CustomersPage'));
const CustomerDetailsPage = lazy(() => import('./components/pages/Customers/Details/CustomerDetailsPage'));
const EditCustomerSection = lazy(() => import('./components/pages/Customers/Details/EditCustomerSection'));
const CustomerCommunicationSection = lazy(() => import('./components/pages/Customers/Details/CustomerCommunicationSection'));
const CustomerTicketsSection = lazy(() => import('./components/pages/Customers/Details/CustomerTicketsSection'));

const SeatPricingDefinitionsPage = lazy(() => import('./components/pages/FareManagement/ProductsPricing/SeatPricingDefinitions/SeatPricingDefinitionsPage'));
const SeatPricingDefinitionEditPage = lazy(() => import('./components/pages/FareManagement/ProductsPricing/SeatPricingDefinitions/SeatPricingDefinition/SeatPricingDefinitionEditPage'));
const ZoneMatrixPriceDefinitionsPage = lazy(() => import('./components/pages/FareManagement/ProductsPricing/ZoneMatrixPriceDefinitions/ZoneMatrixPriceDefinitionsPage'));
const ZoneMatrixPriceDefinitionEditPage = lazy(
  () => import('./components/pages/FareManagement/ProductsPricing/ZoneMatrixPriceDefinitions/ZoneMatrixPriceDefinition/ZoneMatrixPriceDefinitionEditPage')
);
const ZoneMatrixPriceAssignmentsPage = lazy(() => import('./components/pages/FareManagement/ProductsPricing/ZoneMatrixPriceAssignments/ZoneMatrixPriceAssignmentsPage'));
const ZoneMatrixReturnPriceAssignmentsPage = lazy(() => import('./components/pages/FareManagement/ProductsPricing/ZoneMatrixReturnPriceAssignments/ZoneMatrixReturnPriceAssignmentsPage'));
const AdultBasedPricesPage = lazy(() => import('./components/pages/FareManagement/ProductsPricing/AdultBasedPrices/AdultBasedPricesPage'));
const ProductsPage = lazy(() => import('./components/pages/FareManagement/ProductsPricing/Products/ProductsPage'));
const ProductEditPage = lazy(() => import('./components/pages/FareManagement/ProductsPricing/Products/Product/ProductEditPage'));
const SalesChannelsPage = lazy(() => import('./components/pages/FareManagement/SalesChannels/SalesChannelsPage'));
const PaymentProcessorsPage = lazy(() => import('./components/pages/FareManagement/SalesEquipment/PaymentProcessors/PaymentProcessorsPage'));
const PaymentTerminalsPage = lazy(() => import('./components/pages/FareManagement/SalesEquipment/PaymentTerminals/PaymentTerminalsPage'));
const PaymentTerminalTypesPage = lazy(() => import('./components/pages/FareManagement/SalesEquipment/PaymentTerminalTypes/PaymentTerminalTypesPage'));
const SalesEquipmentDevicesPage = lazy(() => import('./components/pages/FareManagement/SalesEquipment/SalesEquipmentDevices/SalesEquipmentDevicesPage'));
const SalesEquipmentDeviceTypesPage = lazy(() => import('./components/pages/FareManagement/SalesEquipment/SalesEquipmentDeviceTypes/SalesEquipmentDeviceTypesPage'));
const OpenTicketValiditiesPage = lazy(() => import('./components/pages/FareManagement/SalesRules/OpenTicketValidities/OpenTicketValiditiesPage'));
const OverbookingsPage = lazy(() => import('./components/pages/FareManagement/SalesRules/Overbookings/OverbookingsPage'));
const SalePeriodsPage = lazy(() => import('./components/pages/FareManagement/SalesRules/SalePeriods/SalePeriodsPage'));
const SaleEndsPage = lazy(() => import('./components/pages/FareManagement/SalesRules/SaleEnds/SaleEndsPage'));
const RoundtripOffersPage = lazy(() => import('./components/pages/FareManagement/SalesRules/RoundtripOffers/RoundtripOffersPage'));
const ShiftsPage = lazy(() => import('./components/pages/FareManagement/Shifts/ShiftsPage'));
const ShiftDetailsPage = lazy(() => import('./components/pages/FareManagement/Shifts/Shift/ShiftDetailsPage'));
const TravellerProfilesPage = lazy(() => import('./components/pages/FareManagement/TravellerProfiles/TravellerProfilesPage'));
const ActiveJourneyPatternsPage = lazy(() => import('./components/pages/TransportNetwork/ActiveNetwork/ActiveJourneyPatterns/ActiveJourneyPatternsPage'));
const ActiveJourneyPatternServiceJourneysPage = lazy(() => import('./components/pages/TransportNetwork/ActiveNetwork/ActiveJourneyPatternServiceJourneys/ActiveJourneyPatternServiceJourneysPage'));
const ActiveServiceJourneysDetailsPage = lazy(
  () => import('./components/pages/TransportNetwork/ActiveNetwork/ActiveJourneyPatternServiceJourneys/ActiveServiceJourney/ActiveServiceJourneyDetailsPage')
);
const ActiveNetworkTreePage = lazy(() => import('./components/pages/TransportNetwork/ActiveNetwork/ActiveNetworkTree/ActiveNetworkTreePage') );
const ActiveLinesPage = lazy(() => import('./components/pages/TransportNetwork/ActiveNetwork/ActiveLines/ActiveLinesPage'));
const ActiveNetworkValidationPage = lazy(() => import('./components/pages/TransportNetwork/ActiveNetwork/ActiveNetworkValidation/ActiveNetworkValidationPage'));
const ActiveStopPointInJourneyPatternsPage = lazy(() => import('./components/pages/TransportNetwork/ActiveNetwork/ActiveStopPointsInJourneyPatterns/ActiveStopPointInJourneyPatternsPage'));
const DatasetsPage = lazy(() => import('./components/pages/TransportNetwork/Networks/Datasets/DatasetsPage'));
const JourneyPatternServiceJourneysPage = lazy(() => import('./components/pages/TransportNetwork/Networks/JourneyPatternServiceJourneys/JourneyPatternServiceJourneysPage'));
const LinesRoutesJourneyPatternsPage = lazy(() => import('./components/pages/TransportNetwork/Networks/LinesRoutesJourneyPatterns/LinesRoutesJourneyPatternsPage'));
const OperatorsPage = lazy(() => import('./components/pages/TransportNetwork/Networks/Operators/OperatorsPage'));
const StopPointInJourneyPatternsPage = lazy(() => import('./components/pages/TransportNetwork/Networks/StopPointsInJourneyPatterns/StopPointInJourneyPatternsPage'));
const QuaysPage = lazy(() => import('./components/pages/TransportNetwork/Quays/QuaysPage'));
const QuaysSyncPage = lazy(() => import('./components/pages/TransportNetwork/Quays/QuaysSyncPage'));
const ZonesPage = lazy(() => import('./components/pages/TransportNetwork/Zones/ZonesPage'));
const ZoneEditPage = lazy(() => import('./components/pages/TransportNetwork/Zones/Zone/ZoneEditPage'));
const ZoneValidationPage = lazy(() => import('./components/pages/TransportNetwork/Zones/ZoneValidationPage'));
const DepartureDiscountsSection = lazy(() => import('./components/pages/FareManagement/ProductsPricing/DepartureDiscount/DepartureDiscountsSection'));

const VehicleTypeForLinesPage = lazy(() => import('./components/pages/Vehicles/VehiclePlanning/VehicleTypeForLines/VehicleTypeForLinesPage'));

const MessagingPage = lazy(() => import('./components/pages/Messaging/MessagingPage'));

const MessageTemplatesPage = lazy(() => import('./components/pages/Settings/MessageTemplates/MessageTemplatesPage'));
const MessageTemplateDetailsPage = lazy(() => import('./components/pages/Settings/MessageTemplates/Details/MessageTemplatePage'));

export const Paths = {
  base: '/',
  stops: {
    base: '/stops',
    manage: '/stops/manage',
    synchronize: '/stops/synchronize'
  },
  networks: {
    base: '/networks',
    datasets: '/networks/datasets',
    operators: '/networks/operators',
    lines: '/networks/linesroutesjourneypatterns',
    travelPatterns: '/networks/travelpatterns',
    stopPointInJourneyPatterns: '/networks/stoppointinjourneypatterns',
    journeyPatternServiceJourneys: '/networks/journeypatternservicejourneys',
    timetables: '/networks/timetables',
    operations: '/networks/operations'
  },
  zones: {
    base: '/zones',
    manage: '/zones/manage',
    validation: '/zones/validation'
  },
  activenetwork: {
    base: '/activenetwork',
    activeNetworkTree: '/activenetwork/tree',
    activeLines: '/activenetwork/activelines',
    activeRoutes: '/activenetwork/activeroutes',
    activeJourneyPatterns: '/activenetwork/activejourneypatterns',
    activeStopPointInJourneyPatterns: '/activenetwork/activestoppointinjourneypatterns',
    activeJourneyPatternServiceJourneys: '/activenetwork/activejourneypatternservicejourneys',
    timetables: '/activenetwork/timetables',
    validation: '/activenetwork/validation'
  },
  travellerProfiles: {
    base: '/travellerprofiles',
    manage: '/travellerprofiles/manage'
  },
  productsPricing: {
    base: '/productspricing',
    zonePricingDefinitions: '/productspricing/zonepricingdefinitions',
    zonePricingAssignments: '/productspricing/zonepricingassignments',
    zoneMatrixReturnPricingAssignments: '/productspricing/zonematrixreturnpricingassignments',
    seatPricingDefinitions: '/productspricing/seatpricingdefinitions',
    seatPricingAssignments: '/productspricing/seatpricingassignments',
    adultBasedPricing: '/productspricing/adultbasedpricing',
    productsAndServices: '/productspricing/productsandservices',
    roundingRules: '/productspricing/roundingrules',
    validation: '/productspricing/validation',
    departureDiscount: '/productspricing/departurediscount'
  },
  salesChannels: {
    base: '/saleschannels',
    manage: '/saleschannels/manage'
  },
  salesRules: {
    base: '/salesrules',
    overbooking: '/salesrules/overbooking',
    salePeriod: '/salesrules/saleperiod',
    saleEnd: '/salesrules/saleend',
    travelrightLimitations: '/salesrules/travelrightlimitations',
    roundtripOffer: '/salesrules/roundtripoffer',
    openTicketValidity: '/salesrules/openticketvalidity',
    priceFixationTime: '/salesrules/pricefixationtime'
  },
  tickets: {
    base: '/tickets',
    sell: '/tickets/sell',
    payment: '/tickets/payment',
    departures: {
      base: '/tickets/departures',
      loadDetails: '/tickets/departures/loaddetails',
      passengerList: '/tickets/departures/passengerlist'
    },
    ticketLayout: '/tickets/ticketlayout'
  },
  shifts: {
    base: '/shifts',
    details: '/shifts/details',
    reports: '/shifts/reports'
  },
  salesEquipment: {
    base: '/salesequipment',
    salesEquipmentDevices: '/salesequipment/salesequipmentdevices',
    salesEquipmentDeviceTypes: '/salesequipment/salesequipmentdevicetypes',
    paymentTerminals: '/salesequipment/paymentterminals',
    paymentTerminalTypes: '/salesequipment/paymentterminaltypes',
    paymentProcessors: '/salesequipment/paymentprocessors'
  },
  customers: {
    base: '/customers',
    manage: {
      base: '/customers/manage',
      edit: '/customers/manage/:customerId/edit',
      communication: '/customers/manage/:customerId/communication',
      tickets: '/customers/manage/:customerId/tickets'
    }
  },
  vehicles: {
    base: '/vehicles'
  },
  vehicleTypes: {
    base: '/vehicletypes'
  },
  vehiclePlanning: {
    base: '/vehicleplanning',
    vehicleTypeForLines: '/vehicleplanning/vehicletypeforlines',
    validation: '/vehicleplanning/validation',
    vehicleTypePlanEntries: '/vehicleplanning/entries'
  },
  reports: {
    base: '/reports',
    financial: {
      base: '/reports/financial',
      depositReport: '/reports/financial/depositreport',
      paymentReport: '/reports/financial/paymentreport',
      revenueRecognitionReport: '/reports/financial/revenuerecognitionreport',
      salesReport: '/reports/financial/salesreport',
      servicesReport: '/reports/financial/servicesreport',
      shiftReport: '/reports/financial/shiftreport',
      ticketsReport: '/reports/financial/ticketsreport',
      ticketPaxReport: '/reports/financial/ticketpax',
      financialReport: '/reports/financial/financialreport',
      xReport: '/reports/financial/xreport',
      zReport: '/reports/financial/zreport'
    },
    operations: {
      base: '/reports/operations',
      departuresReport: '/reports/operations/departuresreport',
      loadReport: '/reports/operations/loadreport',
      stopUsageReport: '/reports/operations/stopusagereport',
      tripsReport: '/reports/operations/tripsreport'
    }
  },

  support: {
    base: '/support'
  },
  users: {
    base: '/users',
    manage: {
      base: '/users/manage',
      details: {
        base: '/users/manage/:userId',
        edit: '/users/manage/:userId/edit',
        messages: '/users/manage/:userId/messages'
      }
    },
    access: {
      base: '/users/access',
      rights: '/users/access/rights',
      roles: '/users/access/roles'
    }
  },
  settings: {
    base: '/settings',
    languages: '/settings/languages',
    visual: '/settings/visual',
    other: '/settings/other',
    messageTemplates: '/settings/messageTemplates',
    messageTemplateDetails: '/settings/messageTemplates/:id'
  },
  messaging: {
    base: '/messaging'
  },
  notFound: '/notfound',
  unauthorized: '/unauthorized'
};

export const AppRoutes = () => {
  const location = useLocation();

  return (
    <Transitions location={location}>
      <Routes location={location}>
        <Route path={Paths.base} element={<RequireRole role={['superadmin', 'support', 'washing_line']} fallback={<Fragment />}><DashboardPage /></RequireRole>} />
        <Route path={Paths.stops.base} element={<RequireRole role='superadmin'><MainStopPage /></RequireRole>}>
          <Route path={Paths.stops.manage} element={<QuaysPage />} />
          <Route path={Paths.stops.synchronize} element={<QuaysSyncPage />} />
        </Route>
        <Route path={Paths.networks.base} element={<RequireRole role='superadmin'><MainNetworksPage /></RequireRole>}>
          <Route path={Paths.networks.datasets} element={<DatasetsPage />} />
          <Route path={Paths.networks.operators} element={<OperatorsPage />} />
          <Route path={Paths.networks.lines} element={<LinesRoutesJourneyPatternsPage />} />
          <Route path={Paths.networks.stopPointInJourneyPatterns} element={<StopPointInJourneyPatternsPage />} />
          <Route path={Paths.networks.journeyPatternServiceJourneys} element={<JourneyPatternServiceJourneysPage />} />
          <Route path={Paths.networks.timetables} element={<Fragment />} />
          <Route path={Paths.networks.operations} element={<Fragment />} />
        </Route>
        <Route path={`${Paths.zones.manage}/create`} element={<RequireRole role='superadmin'><ZoneEditPage /></RequireRole>} />
        <Route path={`${Paths.zones.manage}/:id`} element={<RequireRole role='superadmin'><ZoneEditPage /></RequireRole>} />
        <Route path={Paths.zones.base} element={<RequireRole role='superadmin'><MainZonePage /></RequireRole>}>
          <Route path={Paths.zones.manage} element={<ZonesPage />} />
          <Route path={Paths.zones.validation} element={<ZoneValidationPage />} />
        </Route>
        <Route path={`${Paths.activenetwork.activeJourneyPatternServiceJourneys}/:id`} element={<RequireRole role='superadmin'><ActiveServiceJourneysDetailsPage /></RequireRole>} />
        <Route path={Paths.activenetwork.base} element={<RequireRole role='superadmin'><MainActiveNetworkPage /></RequireRole>}>
          <Route path={Paths.activenetwork.activeNetworkTree} element={<ActiveNetworkTreePage />} />
          <Route path={Paths.activenetwork.activeLines} element={<ActiveLinesPage />} />
          <Route path={Paths.activenetwork.activeJourneyPatterns} element={<ActiveJourneyPatternsPage />} />
          <Route path={Paths.activenetwork.activeStopPointInJourneyPatterns} element={<ActiveStopPointInJourneyPatternsPage />} />
          <Route path={Paths.activenetwork.activeJourneyPatternServiceJourneys} element={<ActiveJourneyPatternServiceJourneysPage />} />
          <Route path={Paths.activenetwork.timetables} element={<Fragment />} />
          <Route path={Paths.activenetwork.validation} element={<ActiveNetworkValidationPage />} />
        </Route>
        <Route path={Paths.travellerProfiles.base} element={<RequireRole role='superadmin'><MainTravellerProfilePage /></RequireRole>}>
          <Route path={Paths.travellerProfiles.manage} element={<TravellerProfilesPage />} />
        </Route>
        <Route path={`${Paths.productsPricing.zonePricingDefinitions}/create`} element={<RequireRole role='superadmin'><ZoneMatrixPriceDefinitionEditPage /></RequireRole>} />
        <Route path={`${Paths.productsPricing.zonePricingDefinitions}/:id`} element={<RequireRole role='superadmin'><ZoneMatrixPriceDefinitionEditPage /></RequireRole>} />
        <Route path={`${Paths.productsPricing.seatPricingDefinitions}/create`} element={<RequireRole role='superadmin'><SeatPricingDefinitionEditPage /></RequireRole>} />
        <Route path={`${Paths.productsPricing.seatPricingDefinitions}/:id`} element={<RequireRole role='superadmin'><SeatPricingDefinitionEditPage /></RequireRole>} />
        <Route path={`${Paths.productsPricing.productsAndServices}/create`} element={<RequireRole role='superadmin'><ProductEditPage /></RequireRole>} />
        <Route path={`${Paths.productsPricing.productsAndServices}/:id`} element={<RequireRole role='superadmin'><ProductEditPage /></RequireRole>} />
        <Route path={Paths.productsPricing.base} element={<RequireRole role='superadmin'><MainProductsPricingPage /></RequireRole>}>
          <Route path={Paths.productsPricing.zonePricingDefinitions} element={<ZoneMatrixPriceDefinitionsPage />} />
          <Route path={Paths.productsPricing.zonePricingAssignments} element={<ZoneMatrixPriceAssignmentsPage />} />
          <Route path={Paths.productsPricing.zoneMatrixReturnPricingAssignments} element={<ZoneMatrixReturnPriceAssignmentsPage />} />
          <Route path={Paths.productsPricing.seatPricingDefinitions} element={<SeatPricingDefinitionsPage />} />
          <Route path={Paths.productsPricing.seatPricingAssignments} element={<Fragment />} />
          <Route path={Paths.productsPricing.adultBasedPricing} element={<AdultBasedPricesPage />} />
          <Route path={Paths.productsPricing.productsAndServices} element={<ProductsPage />} />
          <Route path={Paths.productsPricing.roundingRules} element={<Fragment />} />
          <Route path={Paths.productsPricing.validation} element={<Fragment />} />
          <Route path={Paths.productsPricing.departureDiscount} element={<DepartureDiscountsSection />} />
        </Route>
        <Route path={Paths.salesChannels.base} element={<RequireRole role='superadmin'><MainSalesChannelsPage /></RequireRole>}>
          <Route path={Paths.salesChannels.manage} element={<SalesChannelsPage />} />
        </Route>
        <Route path={Paths.salesRules.base} element={<RequireRole role='superadmin'><MainSalesRulesPage /></RequireRole>}>
          <Route path={Paths.salesRules.overbooking} element={<OverbookingsPage />} />
          <Route path={Paths.salesRules.salePeriod} element={<SalePeriodsPage />} />
          <Route path={Paths.salesRules.saleEnd} element={<SaleEndsPage />} />
          <Route path={Paths.salesRules.travelrightLimitations} element={<Fragment />} />
          <Route path={Paths.salesRules.roundtripOffer} element={<RoundtripOffersPage />} />
          <Route path={Paths.salesRules.openTicketValidity} element={<OpenTicketValiditiesPage />} />
          <Route path={Paths.salesRules.priceFixationTime} element={<Fragment />} />
        </Route>
        <Route path={`${Paths.tickets.base}/sell`} element={<RequireRole role={['superadmin', 'support']}><TicketSellPage /></RequireRole>} />
        <Route path={`${Paths.tickets.base}/payment/:id/callback`} element={<TicketPaymentCallbackPage />} />
        <Route path={`${Paths.tickets.base}/:id`} element={<RequireRole role={['superadmin', 'support']}><TicketEditPage /></RequireRole>} />
        <Route path={Paths.tickets.base} element={<RequireRole role={['superadmin', 'support']}><TicketsPage /></RequireRole>} />
        <Route path={`${Paths.tickets.departures.base}/:serviceJourneyActiveId/:date`} element={<RequireRole role={['superadmin', 'support', 'washing_line']}><DepartureDetailsPage /></RequireRole>} />
        <Route path={Paths.tickets.departures.base} element={<RequireRole role={['superadmin', 'support', 'washing_line']}><DeparturesPage /></RequireRole>}>
          <Route path={Paths.tickets.departures.loadDetails} element={<Fragment />} />
          <Route path={Paths.tickets.departures.passengerList} element={<Fragment />} />
        </Route>
        <Route path={Paths.tickets.ticketLayout} element={<Fragment />} />
        <Route path={`${Paths.shifts.details}/:id`} element={<RequireRole role='superadmin'><ShiftDetailsPage /></RequireRole>} />
        <Route path={Paths.shifts.base} element={<RequireRole role='superadmin'><MainShiftPage /></RequireRole>}>
          <Route path={Paths.shifts.reports} element={<ShiftsPage />} />
        </Route>
        <Route path={Paths.salesEquipment.base} element={<RequireRole role='superadmin'><MainSalesEquipmentPage /></RequireRole>}>
          <Route path={Paths.salesEquipment.salesEquipmentDevices} element={<SalesEquipmentDevicesPage />} />
          <Route path={Paths.salesEquipment.salesEquipmentDeviceTypes} element={<SalesEquipmentDeviceTypesPage />} />
          <Route path={Paths.salesEquipment.paymentTerminals} element={<PaymentTerminalsPage />} />
          <Route path={Paths.salesEquipment.paymentTerminalTypes} element={<PaymentTerminalTypesPage />} />
          <Route path={Paths.salesEquipment.paymentProcessors} element={<PaymentProcessorsPage />} />
        </Route>
        <Route path={Paths.customers.base} element={<RequireRole role={['superadmin', 'support']}><MainCustomerPage /></RequireRole>}>
          <Route path={Paths.customers.manage.base} element={<CustomersPage />} />
        </Route>
        <Route path={`${Paths.customers.manage.base}/:customerId`} element={<RequireRole role={['superadmin', 'support']}><CustomerDetailsPage /></RequireRole>}>
          <Route path={Paths.customers.manage.edit} element={<EditCustomerSection />} />
          <Route path={Paths.customers.manage.communication} element={<CustomerCommunicationSection />} />
          <Route path={Paths.customers.manage.tickets} element={<CustomerTicketsSection />} />
        </Route>
        <Route path={Paths.vehicles.base} element={<RequireRole role='superadmin'><VehiclesPage /></RequireRole>} />
        <Route path={Paths.vehicleTypes.base} element={<RequireRole role='superadmin'><VehicleTypesPage /></RequireRole>} />
        <Route path={Paths.vehiclePlanning.base} element={<RequireRole role='superadmin'><MainVehiclePlanningPage /></RequireRole>}>
          <Route path={Paths.vehiclePlanning.vehicleTypeForLines} element={<VehicleTypeForLinesPage />} />
          <Route path={Paths.vehiclePlanning.validation} element={<Fragment />} />
          <Route path={Paths.vehiclePlanning.vehicleTypePlanEntries} element={<VehicleTypePlanEntriesPage />} />
        </Route>
        <Route path={Paths.users.manage.base} element={<RequireRole role='superadmin'><UsersPage /></RequireRole>} />
        <Route path={Paths.users.manage.details.base} element={<RequireRole role='superadmin'><UserDetailsPage /></RequireRole>}>
          <Route path={Paths.users.manage.details.edit} element={<UserDetailsSection />} />
          <Route path={Paths.users.manage.details.messages} element={<UserMessagesSection />} />
        </Route>
        <Route path={Paths.users.access.base} element={<RequireRole role='superadmin'><MainUserAccessPage /></RequireRole>}>
          <Route path={Paths.users.access.rights} element={<Fragment />} />
          <Route path={Paths.users.access.roles} element={<Fragment />} />
        </Route>
        <Route path={Paths.reports.financial.base} element={<RequireRole role={['superadmin', 'economy']}><MainReportFinancialPage /></RequireRole>}>
          <Route path={Paths.reports.financial.depositReport} element={<DepositReportPage />} />
          <Route path={Paths.reports.financial.paymentReport} element={<PaymentReportPage />} />
          <Route path={Paths.reports.financial.revenueRecognitionReport} element={<RevenueRecognitionReportPage />} />
          <Route path={Paths.reports.financial.salesReport} element={<SalesReportPage />} />
          <Route path={Paths.reports.financial.servicesReport} element={<ServicesReportPage />} />
          <Route path={Paths.reports.financial.shiftReport} element={<ShiftReportPage />} />
          <Route path={Paths.reports.financial.ticketsReport} element={<TicketsReportPage />} />
          <Route path={Paths.reports.financial.ticketPaxReport} element={<TicketPaxReportPage />} />
          <Route path={Paths.reports.financial.financialReport} element={<Fragment />} />
          <Route path={Paths.reports.financial.xReport} element={<Fragment />} />
          <Route path={Paths.reports.financial.zReport} element={<Fragment />} />
        </Route>
        <Route path={Paths.reports.operations.base} element={<RequireRole role={['superadmin', 'economy']}><MainReportOperationsPage /></RequireRole>}>
          <Route path={Paths.reports.operations.departuresReport} element={<DeparturesReportPage />} />
          <Route path={Paths.reports.operations.loadReport} element={<LoadReportPage />} />
          <Route path={Paths.reports.operations.stopUsageReport} element={<StopUsageReportPage />} />
          <Route path={Paths.reports.operations.tripsReport} element={<TripsReportPage />} />
        </Route>
        <Route path={Paths.settings.languages} element={<Fragment />} />
        <Route path={Paths.settings.visual} element={<Fragment />} />
        <Route path={Paths.settings.other} element={<Fragment />} />
        <Route path={Paths.settings.messageTemplates} element={<RequireRole role='superadmin'><MessageTemplatesPage /></RequireRole>} />
        <Route path={Paths.settings.messageTemplateDetails} element={<RequireRole role='superadmin'><MessageTemplateDetailsPage /></RequireRole>} />
        <Route path={Paths.notFound} element={<NotFound />} />
        <Route path={Paths.unauthorized} element={<Unauthorized />} />
        <Route path={Paths.messaging.base} element={<MessagingPage />} />
      </Routes>
    </Transitions>
  );
};